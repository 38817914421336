html {
  background: #ecf0f1;
}

body, h1, h2,p,a {
  font-family: Helvetica, Sans-serif;
  color: #2c3e50;
  margin:0;
  padding:0;
  font-weight:300;
}

h1 {
  font-size: 80px;
}

p {
  font-size: 36px;
  opacity: 0;
  animation: appear 1s 3s forwards;
}
a {
  font-size: 15px;
  opacity: 0;
  animation: appear 2s 4s forwards;
}

.container{
  margin:4em;
  text-align: center;
}

.loader {
  margin:auto;
  margin-top: 100px;
  margin-bottom: 100px;
  border: 16px solid #bdc3c7;
  width: 120px;
  height: 120px;
  border-top-color: #1abc9c;
  border-bottom-color: #3498db;
  border-radius: 50%;
  animation: coloredspin 4s linear infinite;
}

@keyframes spin {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes coloredspin {
  0%{
    transform: rotate(0deg) scale(1);
    border-top-color: #1abc9c;
    border-bottom-color: #1abc9c;
  }
  25% {
    border-top-color: #2ecc71;
    border-bottom-color: #2ecc71;
  }
  50% {
    transform: rotate(360deg) scale(1.05);
    border-top-color: #3498db;
    border-bottom-color: #3498db;
  }
  75% {
    border-top-color: #9b59b6;
    border-bottom-color: #9b59b6;
  }
  100%{
    transform: rotate(720deg) scale(1);
    border-top-color: #1abc9c;
    border-bottom-color: #1abc9c;
  }
}

@keyframes appear {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
